<template>
  <b-card-code>
    <b-card-body class="rows-responsive">
      <basic-table
        :fields="fields"
        :data="items"
        :button-table="nameButton"
        :settings="settings"
        :is-busy="isBusy"
        @changePage="onPagination"
        @onCreate="onCreate"
        @onPerPage="onPerPage"
        @deleteItem="deleteModalFunc"
        @search="searchCategories"
      />
    </b-card-body>
    <b-modal
      v-model="deleteModal"
      hide-footer
      hide-header
    >
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="mb-0">
          {{ $t("category.modal.delete_header") }}
        </h5>

        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="deleteModal = false"
          />
        </div>
      </div>

      <hr>

      <!-- Body -->
      <div>{{ $t("category.modal.delete_text") }}</div>
      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
          variant="outline-secondary"
          class="ml-auto"
          @click="deleteModal = false"
        >
          {{ $t("common.cancel") }}
        </b-button>
        <b-button
          variant="primary"
          class="ml-1"
          @click="deleteItem()"
        >
          {{ $t("common.delete") }}
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>
<script>
import { BCardBody, BModal, BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@/views/drs/components/BCardCode.vue'
import dbCategory from '@/models/category'
import BasicTable from '../components/BasicTable.vue'

export default {
  components: {
    BasicTable,
    BCardCode,
    BCardBody,
    BModal,
    BButton,
  },
  data() {
    return {
      nameButton: 'category.new_category',
      fields: [
        // eslint-disable-next-line
        {
          key: 'code',
          label: 'category.code',
          sortable: true,
          thStyle: { width: '20%' },
        },
        { key: 'name', label: 'category.name', sortable: true },
        // eslint-disable-next-line object-curly-newline
        {
          key: 'products[0].count',
          label: 'category.count_product',
          sortable: true,
          thStyle: { width: '20%' },
        },
        { key: 'actions', label: 'category.actions', sortable: false },
      ],
      items: [],
      settings: {},
      isBusy: false,
      perPage: 50,
      deleteModal: false,
      itemToDelete: null,
      searchItem: '',
    }
  },
  async created() {
    if (this.$route.query.page) {
      this.fetch(Number(this.$route.query.page))
    } else {
      this.fetch(1)
    }
  },
  methods: {
    deleteModalFunc(value) {
      this.itemToDelete = value
      this.deleteModal = true
    },
    async deleteItem() {
      try {
        await dbCategory.delete(this.itemToDelete.id)
        this.fetch(Number(this.$route.query.page) || 1)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('category.toast.success_header')}`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `${this.$i18n.t('category.toast.success_message')}`,
          },
        })
        this.itemToDelete = null
        this.deleteModal = false
      } catch (e) {
        /* eslint-disable-next-line no-console */
        console.error(e)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('category.toast.error_header')}`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${this.$i18n.t('category.toast.error_message')}`,
          },
        })
      }
    },
    async fetch(page) {
      this.isBusy = true
      const response = await dbCategory.get({
        page,
        perPage: this.perPage,
        paginate: true,
        filters: { word: this.searchItem },
      })
      this.isBusy = false
      this.items = JSON.parse(JSON.stringify(response.data))
      delete response.data
      this.settings = response
    },
    onPagination(page) {
      this.fetch(page)
      this.$router.replace({ query: { page } })
    },
    onPerPage(size) {
      this.perPage = size
      this.fetch(1)
      this.$router.replace({ query: { page: 1 } })
    },
    async onCreate(data) {
      dbCategory
        .create({
          categoryId: data.id || null,
          name: data.name,
        })
        .then(() => {
          // refeatch
          this.fetch(Number(this.$route.query.page) || 1)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${this.$i18n.t('toast.title.new_category')}`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `${this.$i18n.t('toast.text.success')}`,
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${this.$i18n.t('toast.title.new_category')}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${this.$i18n.t('toast.text.error')}`,
            },
          })
        })
    },
    searchCategories(item) {
      this.searchItem = item
      if (this.searchItem.length > 2 || this.searchItem.length === 0) this.fetch(1)
    },
  },
}
</script>
